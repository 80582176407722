'use client';

import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MatchCardV2 } from '@pickleballinc/react-ui';
import type { Match } from '@pickleballinc/react-ui/types/Match';
import React, { useEffect, useRef, useState } from 'react';
import type { GridCellProps } from 'react-virtualized';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import type { RenderedSection } from 'react-virtualized/dist/es/Grid';

import animate from '@/modules/ticker/animate';
import { TICKER_MATCH_CARD_WIDTH } from '@/utils/constants';

const cache = new CellMeasurerCache({
	defaultWidth: TICKER_MATCH_CARD_WIDTH,
	minWidth: 75,
	fixedHeight: true
});

interface TickerGridProps {
	results: Match[];
	totalRecords: number;
	fetchNextPage: () => void;
	isFetchingNextPage: boolean;
}

export const TickerScroller = ({ results = [], totalRecords = 0, fetchNextPage, isFetchingNextPage }: TickerGridProps) => {
	const [scrollLeft, setScrollLeft] = useState<number>(0);
	const [renderedSection, setRenderedSection] = useState<RenderedSection | null>(null);
	const [scrolledToEnd, setScrolledToEnd] = useState(false);
	const [needToFetchNext, setNeedToFetchNext] = useState(false);
	const scrollingRef = React.useRef<Grid>(null);
	const fetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (scrollingRef.current) {
			const width = scrollingRef.current.props.width;
			const cardsWidth = results.length * TICKER_MATCH_CARD_WIDTH;
			if (cardsWidth < width) {
				setScrolledToEnd(true);
			}
		}
	}, [scrollingRef.current]);

	useEffect(() => {
		if (needToFetchNext) {
			fetchNextPage();
			setNeedToFetchNext(false);
		}
	}, [needToFetchNext]);

	const handleScroll = (params: any) => {
		if (fetchTimeoutRef.current) {
			clearTimeout(fetchTimeoutRef.current);
		}

		// Debounce needed to prevent spam hitting the internal ticker api endpoint
		fetchTimeoutRef.current = setTimeout(() => {
			if (params.scrollLeft + params.clientWidth >= params.scrollWidth - TICKER_MATCH_CARD_WIDTH * 6) {
				setNeedToFetchNext(true);
			}
		}, 200);

		setScrolledToEnd(params.scrollLeft + params.clientWidth >= params.scrollWidth);
		setScrollLeft(params.scrollLeft);
	};

	const scrollTo = (offset: number = 0) => {
		const cellOffset = scrollingRef.current?.getOffsetForCell({
			columnIndex: offset,
			rowIndex: 1
		});

		if (cellOffset) {
			animate({
				fromValue: scrollLeft,
				toValue: cellOffset.scrollLeft,
				onUpdate: (newScrollLeftValue, callback) => {
					setScrollLeft(newScrollLeftValue);
					if (callback) {
						callback();
					}
				}
			});
		}
	};

	const cellRenderer = ({ columnIndex, key, rowIndex, parent, style }: GridCellProps) => {
		const match = results[columnIndex];

		return (
			<CellMeasurer cache={cache} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
				{({ registerChild }) => (
					<div
						aria-hidden="true"
						className="flex cursor-pointer select-none items-center px-1"
						ref={(element) => {
							if (registerChild) {
								registerChild(element || undefined);
							}
						}}
						style={{
							...style
						}}
					>
						<MatchCardV2 match={match} compact shortenName forTicker />
					</div>
				)}
			</CellMeasurer>
		);
	};

	return (
		<div className="relative block h-[148px] flex-1">
			{scrollLeft > 20 && (
				<div className="absolute inset-y-0 left-0 z-30 hidden w-24 items-center justify-start bg-gradient-to-r from-white sm:flex">
					<div
						aria-hidden="true"
						onClick={() => {
							if (renderedSection) {
								const { columnStartIndex, columnStopIndex } = renderedSection;
								const index = columnStartIndex - (columnStopIndex - columnStartIndex - 1);
								scrollTo(index < 0 ? 0 : index);
							}
						}}
						className="group inline-flex cursor-pointer items-center justify-center rounded-e-full border-y border-r border-gray-200 bg-white transition hover:border-none hover:bg-experiment/70 sm:h-16 sm:w-6"
					>
						<FontAwesomeIcon icon={faArrowLeft} className="stroke-3 text-gray-700 group-hover:text-white" />
					</div>
				</div>
			)}
			{(results.length < totalRecords || !scrolledToEnd) && (
				<div className="absolute inset-y-0 right-0 z-30 hidden w-24 items-center justify-end border-r border-gray-200 bg-gradient-to-l from-white sm:flex">
					<div
						aria-hidden="true"
						onClick={() => {
							if (renderedSection) {
								scrollTo(renderedSection.columnOverscanStopIndex - 1);
							}
						}}
						className={`group inline-flex ${isFetchingNextPage && scrolledToEnd ? '' : 'cursor-pointer'} items-center justify-center rounded-s-full border-y border-s border-gray-200 bg-white transition hover:border-none hover:bg-experiment/70 sm:h-16 sm:w-6`}
					>
						{isFetchingNextPage && scrolledToEnd ? (
							<FontAwesomeIcon icon={faSpinner} spin size="sm" className="stroke-3 text-gray-700 group-hover:text-white" />
						) : (
							<FontAwesomeIcon icon={faArrowRight} className="stroke-3 text-gray-700 group-hover:text-white" />
						)}
					</div>
				</div>
			)}
			<AutoSizer>
				{({ height, width }) => (
					<Grid
						className="no-scrollbar px-1"
						role="grid"
						cellRenderer={cellRenderer}
						columnCount={results.length}
						deferredMeasurementCache={cache}
						scrollToAlignment="start"
						height={height}
						columnWidth={TICKER_MATCH_CARD_WIDTH}
						rowCount={1}
						rowHeight={height}
						width={width}
						scrollLeft={scrollLeft}
						onSectionRendered={(params) => {
							setRenderedSection(params);
						}}
						onScroll={handleScroll}
						ref={scrollingRef}
					/>
				)}
			</AutoSizer>
		</div>
	);
};
