'use client';

import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Match } from '@pickleballinc/react-ui/types/Match';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';

import { TickerScroller } from '@/modules/ticker/TickerScroller';
import { TickerSummary } from '@/modules/ticker/TickerSummary';
import { TickerDataInterface } from '@/modules/ticker/types';
import { TICKER_SCROLL_SIZE } from '@/utils/constants';

export function Ticker({ initialMatches }: { initialMatches: TickerDataInterface }) {
	const { data, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
		queryKey: ['ticker'],
		queryFn: async ({ pageParam = 1 }) => {
			const response = await fetch(`/api/v2/ticker?current_page=${pageParam}`);
			const result: { data: TickerDataInterface; statusCode: number; error?: string } = await response.json();

			if (result.statusCode !== 200 || !result.data || result.data.matches.length === 0) return null;

			return {
				page: pageParam,
				data: result.data
			};
		},
		initialData: {
			pages: [
				{
					page: 1,
					data: initialMatches
				}
			],
			pageParams: [undefined]
		},
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		getNextPageParam: (page) => {
			if (!page) return undefined;

			const totalPages = Math.ceil(page.data.totalRecords / +TICKER_SCROLL_SIZE);
			return page.page + 1 <= totalPages ? page.page + 1 : undefined;
		},
		refetchInterval: 60000 // set to 1min because its cached on backend for 1min as well...
	});

	if (isLoading) {
		return (
			<div className="flex min-h-[148px] items-center justify-center" id="pb-ticker-loader">
				<FontAwesomeIcon icon={faLoader} spin size="lg" />
			</div>
		);
	}

	if (data) {
		const allMatches: Match[] = [];
		let totalRecords: number = 0;

		data.pages.map((page) => {
			if (page && page.data && page.data.matches && page.data.matches.length > 0) {
				allMatches.push(...page.data.matches);
				totalRecords = page.data.totalRecords;
			}
		});

		return (
			<div className="bg-white" id="pb-ticker">
				<div className="mx-auto flex max-w-[1440px]">
					<div className="hidden sm:flex">
						<TickerSummary />
					</div>
					<TickerScroller
						results={allMatches}
						totalRecords={totalRecords}
						fetchNextPage={() => fetchNextPage()}
						isFetchingNextPage={isFetchingNextPage}
					/>
				</div>
			</div>
		);
	}

	return null;
}
