function easing(time: number) {
	// eslint-disable-next-line no-plusplus, no-param-reassign
	return 1 - --time * time * time * time;
}

function getValue(start: number, end: number, elapsed: number, duration: number) {
	if (elapsed > duration) return end;
	return start + (end - start) * easing(elapsed / duration);
}

interface AnimateProps {
	fromValue: number;
	toValue: number;
	onUpdate: (newValue: number, callback?: () => void) => void;
	onComplete?: () => void;
	duration?: number;
}

export default function animate({ fromValue, toValue, onUpdate, onComplete, duration = 600 }: AnimateProps) {
	const startTime = performance.now();

	const tick = () => {
		const elapsed = performance.now() - startTime;

		window.requestAnimationFrame(() =>
			onUpdate(
				getValue(fromValue, toValue, elapsed, duration),
				// Callback
				elapsed <= duration ? tick : onComplete
			)
		);
	};

	tick();
}
